 *   {
    box-sizing: border-box;
}

body    {
    margin: 0;
}

.container  {
    margin: 1rem;
    text-align: center;
}

.nav    {
    background-color: #333;
    color: azure;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a  {
color: inherit;
text-decoration: none;
height: 100%;
display: flex;
align-items: center;
padding: .25rem;
}

.nav li.active  {
    background-color: #555;
}

.nav li:hover  {
    background-color: #777;
}

